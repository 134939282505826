





























import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator'
import Tinymce from '@/components/Tinymce/index.vue'
@Component({
  name: 'InfoSection',
  components: {
    Tinymce
  }
})

export default class extends Vue {
  @Prop({ required: true }) private template!: any
  @Prop({ required: true }) private variableName!: string
  @Prop({ required: true }) private imageName!: string
  @Prop({ required: true }) private language!: string
  @Prop({ required: true }) private section!: string
  @Prop({ required: false }) private upload!: any[] | undefined
  @Prop({ required: false }) private fallback!: boolean | undefined
  private imageUrl = ''

  private handleAvatarSuccess(res: any, file: any) {
    this.upload![0] = file
    this.template.meta[this.imageName] = file.name
    this.imageUrl = URL.createObjectURL(file.raw)
  }

  private switchUrl():string {
    // eslint-disable-next-line eqeqeq
    if (this.imageUrl.length > 1) {
      return this.imageUrl
    }
    return '/static/public/offices/' + (this.fallback ? 'default' : this.template.path) + '/' + this.section + '/' + this.template.meta[this.imageName]
  }

  private beforeAvatarUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      this.$message.error('Avatar picture size can not exceed 2MB!')
    }
    return isLt2M
  }
}
