

























import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'ImageGallery'
})

export default class extends Vue {
  private selectedTemplate = ''
  private templateUrls = [
    'static/public/u/u1.jpg',
    'static/public/u/u2.jpg',
    'static/public/u/u3.jpg',
    'static/public/u/u4.jpg',
    'static/public/u/u5.jpg',
    'static/public/u/u6.jpg',
    'static/public/u/u7.jpg',
    'static/public/u/u8.jpg',
    'static/public/u/u9.jpg',
    'static/public/u/u10.jpg'
  ]
}
