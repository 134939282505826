




























































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Sticky from '@/components/Sticky/index.vue'
import PageSection from './components/PageSection.vue'
import InfoSection from './components/InfoSection.vue'
import ImageSection from './components/ImageSection.vue'
import ImageGallery from './components/ImageGallery.vue'
import { convertToMapTranslations, errorMsg, hasPermission, locales, successMsg } from '@/utils'
import { getThemeTemplate, saveThemeTemplate, saveThemeTemplateWithMedia } from '@/api/themeTemplates'
import Tinymce from '@/components/Tinymce/index.vue'

import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/search/matchesonscrollbar.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/hint/show-hint.css'
// theme css
import 'codemirror/theme/darcula.css'
import 'codemirror/theme/bespin.css'
// require b -line.js
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/lint/lint.js'
import 'codemirror/addon/lint/css-lint.js'
import 'codemirror/addon/lint/javascript-lint.js'
import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/hint/css-hint.js'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/css/css.js'

import { JSHINT } from 'jshint'
import { CSSLint } from 'csslint'
import { ThemeTemplateTranslation } from '@/models/ThemeTemplateTranslation';
import { UserModule } from '@/store/modules/user'
// eslint-disable-next-line dot-notation
(window as any)['JSHINT'] = JSHINT as any
// eslint-disable-next-line dot-notation
(window as any)['CSSLint'] = CSSLint as any

@Component({
  name: 'EditOfficePage',
  components: {
    Sticky,
    PageSection,
    InfoSection,
    ImageSection,
    ImageGallery,
    Tinymce,
    codemirror
  }
})

export default class extends Vue {
  private loading = false
  private activeNames = ['general']
  private activeTab = '1'
  private activeTab2 = '1'

  private jsOptions = {
    tabSize: 2,
    mode: 'text/javascript',
    theme: 'darcula',
    lineNumbers: true,
    line: true,
    styleActiveLine: true,
    autoRefresh: true,
    gutters: ['CodeMirror-lint-markers'],
    hint: true,
    lint: true,
    autoCloseBrackets: true
  }

  private cssOptions = {
    tabSize: 2,
    mode: 'text/css',
    theme: 'darcula',
    lineNumbers: true,
    line: true,
    styleActiveLine: true,
    autoRefresh: true,
    gutters: ['CodeMirror-lint-markers'],
    hint: true,
    lint: true,
    autoCloseBrackets: true
  }

  private languages = locales
  private language = locales[0]
  private skeletonLoader = false
  private path = 'default'
  private fallback = false
  private landingImages = [null, null, null, null, null]
  private infoImages = [null]
  private jobsImages = [null]
  private logoImages = [null]
  private availableMenu = ['home', 'franchise', 'favorites', 'jobs', 'info', 'blog', 'our_offices', 'team']

  tabsClicked(e:any) {
    if (['cssMirror', 'jsMirror'].includes(e.paneName)) {
      this.refresh(e.paneName)
    }
  }

  refresh(mirror: string) {
    try {
      (this.$refs[mirror] as any).refresh()
    } catch (e) {
      console.log(e)
    }
  }

  async created() {
    if (this.$route.params && this.$route.params.id) {
      this.path = this.$route.params.id
    }else  {
      if(!hasPermission(["EDIT_OTHERS_THEME_TEMPLATES"])){
        this.path = UserModule.officeId
      }
    }

    await this.getItem(this.path)
    if (this.page.path !== this.path) {
      this.page.path = this.path
      this.page.meta.path = this.path
      this.fallback = true
      delete this.page.id
    }
  }

  private async getItem(path: string) {
    this.skeletonLoader = true
    try {
      const { data } = await getThemeTemplate({
        path
      })
      data.meta.translations = convertToMapTranslations(Object.keys(data.meta.translations).map((locale: string) => {
        return Object.assign(data.meta.translations[locale], { locale })
      }), ThemeTemplateTranslation)
      this.page = data
    } catch (err) {}
    this.skeletonLoader = false
  }

  private page = {
    id: undefined,
    date_created: null,
    date_updated: null,
    path: '',
    meta: {
      path: '',
      facebook_page: '',
      linkedin_page: '',
      youtube_page: '',
      section1_image: '',
      section2_image: '',
      section3_image: '',
      section4_image: '',
      section5_image: '',
      new_jobs_image: '',
      info_image: '',
      logo_image: '',
      main_color: '',
      footer_color: '',
      translations: convertToMapTranslations([], ThemeTemplateTranslation),
      translation: null
    }
  }

  private async submitForm() {
    this.loading = true
    try {
      const images = [
        ...this.landingImages.filter(it => it != null),
        ...this.infoImages.filter(it => it != null),
        ...this.jobsImages.filter(it => it != null),
        ...this.logoImages.filter(it => it != null)
      ] as any[]
      if (images.length === 0) {
        await saveThemeTemplate(this.page)
      } else {
        const formData = new FormData()
        images.forEach(image => formData.append('mainPageImages', image?.raw))
        formData.append('template', JSON.stringify(this.page))
        await saveThemeTemplateWithMedia(formData)
      }

      await successMsg('officeDetail.successSave')
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.loading = false
  }
}
