/* eslint-disable camelcase */
export class ThemeTemplateTranslation {
  jobs: string
  blog: string
  info: string
  section1_title: string
  section1_text: string
  section2_title: string
  section2_text: string
  section3_title: string
  section3_text: string
  section4_title: string
  section4_text: string
  section5_title: string
  section5_text: string
  section6_title: string
  section6_text: string
  footer: string

  constructor(data: any = {}) {
    this.jobs = data.jobs || ''
    this.blog = data.blog || ''
    this.info = data.info || ''
    this.section1_title = data.section1_title || ''
    this.section1_text = data.section1_text || ''
    this.section2_title = data.section2_title || ''
    this.section2_text = data.section2_text || ''
    this.section3_title = data.section3_title || ''
    this.section3_text = data.section3_text || ''
    this.section4_title = data.section4_title || ''
    this.section4_text = data.section4_text || ''
    this.section5_title = data.section5_title || ''
    this.section5_text = data.section5_text || ''
    this.section6_title = data.section6_title || ''
    this.section6_text = data.section6_text || ''
    this.footer = data.footer || ''
  }
}
