import request from '@/utils/request'
export const getThemeTemplate = (params: any) =>
  request({
    method: 'get',
    url: '/api/theme_templates/getThemeTemplate',
    params
  })

export const saveThemeTemplate = (data: any) =>
  request({
    method: 'post',
    url: '/api/theme_templates/saveThemeTemplate',
    data
  })

export const saveThemeTemplateWithMedia = (formData: any) =>
  request.post('/api/theme_templates/saveThemeTemplateWithMedia', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
